<template>
    <div class="map">
        <div class="beijing">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name">Bei Jing</div>
        </div>
        <div class="shanghai">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name">Shang Hai</div>
        </div>
        <div class="shenzhen">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name">Shen Zhen</div>
        </div>
        <div class="suzhou">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name_su">Su Zhou</div>
        </div>
        <div class="xianggang">
            <div class="dotted"></div>
            <div class="purple1"></div>
            <div class="purple2"></div>
            <div class="purple3"></div>
            <div class="name_xiang">Hong Kong</div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    }
}
</script>
<style lang="scss">
.map {
    position: relative;
    margin-top: 15px;
    width: 806px;
    height: 605px;
    background: url('../assets/images/index/map.png') no-repeat center center;
    background-size: cover;
    .dotted {
        width: 8px;
        height: 8px;
        background-color: #25a9e0;
        border-radius: 50%;
    }
    div[class^='purple'] {
        position: absolute;
        width: 8px;
        height: 8px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        box-shadow: 0 0 6px #25a9e0;
    }
    .purple1 {
        animation: suo 1.5s linear 0s infinite;
    }
    .purple2 {
        animation: suo 1.5s linear 0.8s infinite;
    }
    .purple3 {
        animation: suo 1.5s linear 1.2s infinite;
    }
    .name {
        position: absolute;
        top: -80%;
        left: 160%;
        width: 78px;
        color: #25a9e0;
        font-weight: 600;
    }
    .name_su {
        position: absolute;
        top: -80%;
        left: -66px;
        width: 73px;
        color: #25a9e0;
        font-weight: 600;
    }
    .name_xiang {
        position: absolute;
        top: -80%;
        left: -88px;
        width: 82px;
        color: #25a9e0;
        font-weight: 600;
    }
    /* 区域地点 */
    // 北京
    .beijing {
        position: absolute;
        right: 212px;
        top: 233px;
    }
    // 上海
    .shanghai {
        position: absolute;
        right: 105px;
        top: 371px;
    }
    .suzhou {
        position: absolute;
        right: 117px;
        top: 371px;
    }
    // 深圳
    .shenzhen {
        position: absolute;
        right: 211px;
        bottom: 76px;
    }
    .xianggang {
        position: absolute;
        right: 211px;
        bottom: 68px;
    }
}
@keyframes suo {
    0% {}
    70% {
        width: 40px;
        height: 40px;
        opacity: 1;
    }
    100% {
        width: 60px;
        height: 60px;
        opacity: 0;
    }
}
@media (max-width: 768px) {
    .map {
    position: relative;
    margin-top: 0;
    width: 100%;
    height: 6rem!important;
    background: url('../assets/images/index/map.png') no-repeat center center;
    background-size: contain;
    .dotted {
        width: 4px;
        height: 4px;
        background-color: #25a9e0;
        border-radius: 50%;
    }
    div[class^='purple'] {
        position: absolute;
        width: 3px;
        height: 3px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        box-shadow: 0 0 4px #25a9e0;
    }
    .purple1 {
        animation: suo 1.5s linear 0s infinite;
    }
    .purple2 {
        animation: suo 1.5s linear 0.8s infinite;
    }
    .purple3 {
        animation: suo 1.5s linear 1.2s infinite;
    }
    div[class^='name'] {
        font-size: 12px;
    }
    .name {
        position: absolute;
        top: -80%;
        left: 160%;
        width: 30px;
        color: #25a9e0;
        font-weight: 600;
    }
    .name_su {
        position: absolute;
        top: -80%;
        left: -55px;
        width: 73px;
        color: #25a9e0;
        font-weight: 600;
    }
    .name_xiang {
        position: absolute;
        top: -80%;
        left: -75px;
        width: 82px;
        color: #25a9e0;
        font-weight: 600;
    }
    /* 区域地点 */
    // 北京
    .beijing {
        position: absolute;
        right: 1.8rem;
        top: 2.3rem;
    }
    // 上海
    .shanghai {
        position: absolute;
        right: 0.9rem;
        top: 3.55rem;
    }
    .suzhou {
        position: absolute;
        right: 1rem;
        top: 3.55rem;
    }
    // 深圳
    .shenzhen {
        position: absolute;
        right: 1.85rem;
        bottom: 1.05rem;
    }
    .xianggang {
        position: absolute;
        right: 1.85rem;
        bottom: .95rem;
    }
}
}
</style>
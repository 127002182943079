<template>
  <div class="about">
    <!-- PC -->
    <div
      class="
        productTop
        hidden-xs-only
        wow
        animate__animated animate__fadeInDown
      "
    >
      <img src="../../assets/images/swiper/swiperItem1.png" alt="" />
    </div>
    <!-- mobile -->
    <div
      class="
        mobileProductTop
        hidden-sm-and-up
        wow
        animate__animated animate__fadeInDown
      "
    >
      <img src="../../assets/images/mobile/index/index_1.png" alt="" />
    </div>
    <div class="location hidden-xs-only">
        <div class="section wow animate__animated animate__fadeInLeft">
            <div class="tarbar">
                <span>Location：</span>
                <span @click="handleChange(1)">HOME</span>
                <span>></span>
                <span class="newsCenter">About US</span>
            </div>
        </div>
    </div>
    <!-- pc -->
    <div class="aboutContent hidden-xs-only">
        <div class="companyProfile wow animate__animated animate__fadeInDown">
            <div class="section">
                <div class="title">
                    <div class="title_one">
                        <span>Company Profile</span>
                    </div>
                </div>
                <div class="content_top">
                    <div>Network Joint was founded in 2014 by a group of lT&T experts specializing in Data Center operations and Telecom Network solutions. We provide various kind of IT and communication infrastructure network services. In order to build good reputation in the field of IT&T industry and become a key player, we must believe in treating our customers with respect and faith. Our main focus to build long term relationships with our customers and clients and provide exceptional customer services by pursuing business through innovation and advanced technology. By developing a strong base of key clients and partners, we are always looking for regional expansion in the lT&T service management.
We have obtained various licenses issued by the Ministry of industry and Information Technology of People 's Republic of China and Hong Kong to operate in the region.</div>
                </div>
                <div class="content_bottom">
                    <!-- <img src="../../assets/images/about/about.png" alt=""> -->
                    <Mapsen></Mapsen>
                </div>
            </div>
        </div>
        <div class="development wow animate__animated animate__fadeInDown">
            <div class="section">
                <div class="title">
                    <div class="title_one">
                        <span>Our Footprint</span>
                    </div>
                </div>
            </div>
            <div class="development_bottom">
                <div class="section">
                    <swiper :options="swiperOptionThree" ref="mySwiperThree">
                       <swiper-slide
                          class="swiper-slide"
                          v-for="(item, index) in developmentList"
                          :key="index"
                          >
                          <div class="slide_item">
                             <!-- <img :src="item.imgSrc" /> -->
                             <div class="slide_item_content">
                                <div class="itemtitle">{{item.title}}</div>
                                <div class="itemtime">{{item.time}}</div>
                             </div>
                             <div class="swiper_circle"></div>
                          </div>
                       </swiper-slide>
                    <div class="swiper-button-prev" slot="button-prev">&lt;</div>
                    <div class="swiper-button-next" slot="button-next">&gt;</div>
                    </swiper>
                </div>
                <div class="swiper_timeLine"></div>
            </div>
        </div>
        <div class="maps wow animate__animated animate__fadeInDown">
            <div class="section">
                <div class="title">
                    <div class="title_one">
                        <span>Contact US</span>
                    </div>
                </div>
                <div class="content">
                    <div class="left">
                        <div class="item" v-for="(Item,index) in positionList" :key="index">
                            <div class="itemName" @click="onClick(index)" :class="{ selected: active == index }">{{Item.name}}</div>
                        </div>
                    </div>
                    <div class="right">
                        <baidu-map class="bm-view" :center="position1" :zoom="zoom">
                            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                            <bm-geolocation
                                anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                                :showAddressBar="true"
                                :autoLocation="true"
                            ></bm-geolocation>
                            <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
                            <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
                            <bm-info-window :position="position1" show>
                                <p>Address：{{ItemMap.address}}</p>
                                <!-- <p>电话：{{ItemMap.phone}}</p>
                                <p>传真：{{ItemMap.chuan}}</p>
                                <p>邮箱：{{ItemMap.mail}}</p> -->
                            </bm-info-window>
                        </baidu-map>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- mobile -->
    <div class="mobileAbout hidden-sm-and-up">
        <div class="companyProfile wow animate__animated animate__fadeInDown">
            <div class="section">
                <div class="title">
                    <div class="title_one">
                        <span>Company Profile</span>
                    </div>
                </div>
                <div class="content_bottom">
                    <!-- <img src="../../assets/images/about/about.png" alt=""> -->
                    <Mapsen></Mapsen>
                </div>
                <div class="content_top">
                    <div>Network Joint was founded in 2014 by a group of lT&T experts specializing in Data Center operations and Telecom Network solutions. We provide various kind of IT and communication infrastructure network services. In order to build good reputation in the field of IT&T industry and become a key player, we must believe in treating our customers with respect and faith. Our main focus to build long term relationships with our customers and clients and provide exceptional customer services by pursuing business through innovation and advanced technology. By developing a strong base of key clients and partners, we are always looking for regional expansion in the lT&T service management.
We have obtained various licenses issued by the Ministry of industry and Information Technology of People 's Republic of China and Hong Kong to operate in the region.</div>
                </div>
            </div>
        </div>
        <div class="development wow animate__animated animate__fadeInDown">
            <div class="section">
                <div class="title">
                    <div class="title_one">
                        <span>Our Footprint</span>
                    </div>
                </div>
            </div>
            <div class="development_bottom">
                <div class="section">
                    <swiper :options="swiperOption" ref="mySwiperThree">
                       <swiper-slide
                          class="swiper-slide"
                          v-for="(item, index) in developmentList"
                          :key="index"
                          >
                          <div class="slide_item">
                             <!-- <img :src="item.imgSrc" /> -->
                             <div class="slide_item_content">
                                <div class="itemtitle">{{item.title}}</div>
                                <div class="itemtime">{{item.time}}</div>
                             </div>
                             <div class="swiper_circle"></div>
                          </div>
                       </swiper-slide>
                    <div class="swiper-button-prev" slot="button-prev">&lt;</div>
                    <div class="swiper-button-next" slot="button-next">&gt;</div>
                    </swiper>
                </div>
                <div class="swiper_timeLine"></div>
            </div>
        </div>
        <div class="maps wow animate__animated animate__fadeInDown">
            <div class="section">
                <div class="title">
                    <div class="title_one">
                        <span>Contact US</span>
                    </div>
                </div>
                <div class="content">
                    <div class="left">
                        <div class="item" v-for="(Item,index) in positionList" :key="index">
                            <div class="itemName" @click="onClick(index)" :class="{ selected: active == index }">{{Item.name}}</div>
                        </div>
                    </div>
                    <div class="right">
                        <baidu-map class="bm-view" :center="position1" :zoom="zoom">
                            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                            <bm-geolocation
                                anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                                :showAddressBar="true"
                                :autoLocation="true"
                            ></bm-geolocation>
                            <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
                            <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
                            <bm-info-window :position="position1" show>
                                <p>Address：{{ItemMap.address}}</p>
                                <!-- <p>电话：{{ItemMap.phone}}</p>
                                <p>传真：{{ItemMap.chuan}}</p>
                                <p>邮箱：{{ItemMap.mail}}</p> -->
                            </bm-info-window>
                        </baidu-map>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import Mapsen from '../../components/mapsen.vue'
export default {
  components: { Mapsen },
  data() {
    return {
        active:0,
        zoom: 16,
        positionList: [
        {
          name:'Shang Hai',
          lng: 121.3909,
          lat: 31.181969,
          address: "Debiyi Garden, 1189 Wuzhong Road, Minhang District, Shanghai",
        //   phone:'(852) 6877 6882',
        //   chuan:'(852) 35719160',
        //   mail:'info@networkjoint.com'
        },
        {
          name:'Su Zhou',
          lng: 120.677878,
          lat: 31.332135,
          address: "Microge Science Innovation Park, 99 Suhong West Road, SIP, Jiangsu Province",
        //   phone:'(852) 6877 6882',
        //   chuan:'(852) 35719160',
        //   mail:'info@networkjoint.com'
        },
        {
          name:'Hong Kong',
          lng: 114.228315,
          lat: 22.318579,
          address: "RM1501-C1, Grand Millennium Plaza (Lower Block), 181 Queen's Road Central, Hong Kong",
        //   phone:'(852) 6877 6882',
        //   chuan:'(852) 35719160',
        //   mail:'info@networkjoint.com'
        },
        {
          name:'Bei Jing',
          lng: 116.423186,
          lat: 39.89877,
          address: "Fengtai Center, 2 Zhushikou East Street, Dongcheng District, Beijing",
        //   phone:'(852) 6877 6882',
        //   chuan:'(852) 35719160',
        //   mail:'info@networkjoint.com'
        }
        ],
        ItemMap:{},
        position1: { lng: 121.3909, lat: 31.181969 },
        developmentList:[
            {
                time:'2014',
                title:'Funded in 2014'
            },
            {
                time:'2015',
                title:'We designed our backbone and GNSOC in 2015'
            },
            // {
            //     time:'2016',
            //     title:'香港总部获得香港电信运营牌照'
            // },
            {
                time:'2017',
                title:'We Get License of Telecom from MIIT in 2017'
            },
            {
                time:'2018',
                title:'We fund Network Joint Hong Kong in 2018 with SBO License owned'
            },
            {
                time:'2019',
                title:'We became APAC strategy partners of a few T1 global carriers'
            },
        ],
        swiperListThree: [],
        swiperOptionThree: {
            loop: false, //是否循环轮播
            autoplay: false, //可选选项，自动滑动
            slidesPerView: 5,
            spaceBetween: 15,
            //左右切换
            navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            },
        },
        swiperOption: {
            loop: false, //是否循环轮播
            autoplay: false, //可选选项，自动滑动
            slidesPerView: 3,
            spaceBetween: 15,
            //左右切换
            navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            },
        },
    };
  },
  mounted() {
    new this.$wow.WOW().init();
    this.getMap(0)
  },
  created() {
  },
  methods:{
    handleChange(num) {
      this.$store.commit("changeNum", num);
      this.$router.push('/indexEn')
    },
    onClick(index) {
        this.active = index
        this.getMap(index)
    },
    getMap(indexs){
        this.position1 = {
          lng: this.positionList[indexs].lng,
          lat: this.positionList[indexs].lat
        };
        // this.address1 = this.positionList[indexs].address;
        this.ItemMap = this.positionList[indexs]
    },
  }
};
</script>
<style lang="scss">
.about {
    padding-top:92px ;
  .productTop {
    img {
      width: 100%;
      height: 482px;
    }
  }
  .location {
    padding: 50px 0;
    .section {
        font-size: 18px;
        .tarbar {
            cursor: pointer;
            .newsCenter {
                color: #197dbe;
            }
        }
    }
  }
  .newsContent {
    .section {
        .title {
            padding-bottom: 82px;
            text-align: center;
            display: flex;
            flex-direction: column;
            .title_one {
            span {
                font-size: 36px;
                display: inline-block;
                background-image: linear-gradient(
                to right,
                #25a9e0,
                #187aba
                ); //最后三行为文字渐变CSS
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            }
            .title_two {
            margin-top: 8px;
            span {
                font-size: 16px;
                display: inline-block;
                background-image: linear-gradient(
                to right,
                #25a9e0,
                #187aba
                ); //最后三行为文字渐变CSS
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            }
        }
        .content{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .Item {
                padding-top:60px;
                width: 600px;
                .ItemContent{
                    width: 512px;
                    img {
                        width: 512px;
                        height: 186px;
                        transition: all 0.3s linear;
                    }
                    .title{
                        font-size: 24px;
                        text-align: left;
                        padding: 36px 0;
                        overflow : hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                    }
                    .content{
                        font-size: 18px;
                        line-height: 26px;
                        overflow : hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                    }
                    .time{
                        font-size: 60px;
                        padding: 10px 0 30px 0;
                    }
                }
            }
            .Item:first-child {
                padding-top:0;
            }
            .Item:nth-child(2) {
                padding-top:0;
            }
            
            .Item:nth-child(2n) {
                padding-left:88px;
                border-bottom: 1px solid #ededed;
            }
            .Item:nth-child(2n-1) {
                border-right: 1px solid #ededed;
                border-bottom: 1px solid #ededed;
            }
            .Item:nth-child(5) {
                border-bottom: none;
            }
            .Item:nth-child(6) {
                border-bottom: none;
            }
            .Item:hover {
                .ItemContent {
                    img {
                        transform: scale(1.1);
                    }
                    .title,.content,.time {
                        // display: inline-block;
                        background-image: linear-gradient(
                        to right,
                        #25a9e0,
                        #187aba
                        ); //最后三行为文字渐变CSS
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }
    }
  }
  .aboutContent {
    .companyProfile {
        .section {
            .title {
                text-align: center;
                padding-bottom: 50px;
                display: flex;
                flex-direction: column;
                .title_one {
                span {
                    font-size: 36px;
                    display: inline-block;
                    background-image: linear-gradient(
                    to right,
                    #25a9e0,
                    #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                }
                .title_two {
                span {
                    font-size: 18px;
                    display: inline-block;
                    background-image: linear-gradient(
                    to right,
                    #25a9e0,
                    #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                }
            }
            .content_top {
                div {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
            .content_bottom {
                display: flex;
                justify-content: center;
                padding: 10px 0 30px 0;
                img {
                  height: 605px;
                }
            }
        }
    }
    .development {
        height: 504px;
        background: url('../../assets/images/index/development.png') no-repeat center center;
        background-size: cover;
        .section {
            .title {
                text-align: center;
                padding: 60px 0 50px 0;
                display: flex;
                flex-direction: column;
                .title_one {
                    span {
                        display: inline-block;
                        font-size: 36px;
                        color: #fff;
                    }
                }
                .title_two {
                    span {
                        display: inline-block;
                        font-size: 18px;
                        color: #fff;
                    }
                }
            }
        }
        .development_bottom {
            position: relative;
            color: #fff;
            .section {
                .swiper-container {
                    // height: 280px;
                    // border-bottom:2px solid red ;
                    // position: relative;
                    .swiper-wrapper {
                        // position: absolute;
                        // height: 100px;
                        margin-top: 10px;
                        .swiper-slide  {
                            .slide_item {
                                img {
                                    width: 100%;
                                }
                                .slide_item_content {
                                    .itemtitle {
                                        height: 60px;
                                        font-size: 17px;
                                    }
                                    .itemtime{
                                        font-size: 17px;
                                    }
                                }
                                .swiper_circle::after {
                                    position: relative;
                                    top: 0;
                                    content: '▲';
                                }
                                
                            }
                        }
                        .swiper-slide:nth-child(2n)  {
                            margin-top: 105px;
                            .slide_item {
                                .slide_item_content {
                                    display: flex;
                                    flex-direction: column-reverse;
                                }
                                .swiper_circle::after {
                                    position: relative;
                                    top: -95px;
                                    content: '▼';
                                }
                                
                            }
                        }
                        
                    }
                }
                .swiper-button-prev {
                    font-family: 宋体;
                    font-size: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;
                    color:#888888;
                    left: 0;
                    top: 119px;
                    background: none;
                    height: 20px;
                    width: 20px;
                    background-color: #fff;
                    border-radius: 20px;
                }
                .swiper-button-next {
                    font-family: 宋体;
                    font-size: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;
                    color:#888888;
                    right: 0;
                    top: 119px;
                    background: none;
                    height: 20px;
                    width: 20px;
                    background-color: #fff;
                    border-radius: 20px;
                }
            }
            .swiper_timeLine {
                position: absolute;
                top: 107px;
                left: 0;
                margin: auto;
                width: 100%;
                // background-color: #5583f9;
                border-bottom: 1px solid #ffffff;
            }
        }
    }
    .maps {
        height: 370px;
        margin-bottom: 260px;
        .section {
            .title {
                text-align: center;
                padding: 82px 0 40px 0;
                display: flex;
                flex-direction: column;
                .title_one {
                span {
                    font-size: 36px;
                    display: inline-block;
                    background-image: linear-gradient(
                    to right,
                    #25a9e0,
                    #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                }
                .title_two {
                span {
                    font-size: 18px;
                    display: inline-block;
                    background-image: linear-gradient(
                    to right,
                    #25a9e0,
                    #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                }
            }
            .content {
                display: flex;
                .left {
                    width: 14%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    padding: 0 20px 0 10px;
                    .item{
                        font-size: 20px;
                        .itemName {
                            padding: 0 0 10px 4px;
                            cursor: pointer;
                        }
                        .selected {
                            border-bottom: 3px solid #197dbe;
                            color: #197dbe;
                        }
                    }
                }
                .right {
                    width: 86%;
                    height: 370px;
                    .bm-view {
                       height: 370px;
                    }
                }
            }
        }
    }
  }
}
@media (max-width: 768px) {
  .about {
    padding-top:0;
    .mobileProductTop {
      img {
        height: 4rem;
      }
    }
    .mobileAbout {
        // padding: 0 .4rem;
        padding-bottom: .4rem;
        .companyProfile {
            .section {
                .title{
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    padding: .3rem 0;
                    .title_one{
                        span {
                            font-size: .45rem;
                            display: inline-block;
                            background-image: linear-gradient(
                            to right,
                            #25a9e0,
                            #187aba
                            ); //最后三行为文字渐变CSS
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                    .title_two {
                        span {
                            font-size: .25rem;
                            display: inline-block;
                            background-image: linear-gradient(
                            to right,
                            #25a9e0,
                            #187aba
                            ); //最后三行为文字渐变CSS
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                }
                .content_bottom {
                    width: 100%;
                    img {
                        width: 100%;
                        height: 6rem;
                    }
                }
                .content_top {
                    div {
                        line-height: .45rem;
                    }
                }
            }
        }
        .development {
            margin:.3rem 0;
            // background-color: #14689d;
            background: url('../../assets/images/index/mobile_dev.png') no-repeat center center;
            background-size: cover;
            width: 100%;
            height: 6rem;
            .section {
                .title{
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    padding: .3rem 0;
                    .title_one{
                        span {
                            font-size: .45rem;
                            color: #fff;
                        }
                    }
                }
            }
            .development_bottom {
            position: relative;
            color: #fff;
            .section {
                .swiper-container {
                    // height: 280px;
                    // border-bottom:2px solid red ;
                    // position: relative;
                    .swiper-wrapper {
                        // position: absolute;
                        // height: 100px;
                        margin-top: 20px;
                        .swiper-slide  {
                            .slide_item {
                                img {
                                    width: 100%;
                                }
                                .slide_item_content {
                                    .itemtitle {
                                        height: 55px;
                                        font-size: .2rem;
                                        line-height: .3rem;
                                    }
                                    .itemtime{
                                        font-size: .2rem;
                                    }
                                }
                                .swiper_circle::after {
                                    position: relative;
                                    top: 0;
                                    content: '▲';
                                }
                                
                            }
                        }
                        .swiper-slide:nth-child(2n)  {
                            margin-top: 105px;
                            .slide_item {
                                .slide_item_content {
                                    display: flex;
                                    flex-direction: column-reverse;
                                }
                                .swiper_circle::after {
                                    position: relative;
                                    top: -95px;
                                    content: '▼';
                                }
                                
                            }
                        }
                        
                    }
                }
                .swiper-button-prev {
                    font-family: 宋体;
                    font-size: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;
                    color:#888888;
                    left: 0;
                    top: 119px;
                    background: none;
                    height: 20px;
                    width: 20px;
                    background-color: #fff;
                    border-radius: 20px;
                }
                .swiper-button-next {
                    font-family: 宋体;
                    font-size: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;
                    color:#888888;
                    right: 0;
                    top: 119px;
                    background: none;
                    height: 20px;
                    width: 20px;
                    background-color: #fff;
                    border-radius: 20px;
                }
            }
            .swiper_timeLine {
                position: absolute;
                top: 106px;
                left: 0;
                margin: auto;
                width: 100%;
                // background-color: #5583f9;
                border-bottom: 1px solid #ffffff;
            }
        }
        }
        .maps {
            .section {
                .title{
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    padding: .3rem 0;
                    .title_one{
                        span {
                            font-size: .45rem;
                            display: inline-block;
                            background-image: linear-gradient(
                            to right,
                            #25a9e0,
                            #187aba
                            ); //最后三行为文字渐变CSS
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                    .title_two {
                        span {
                            font-size: .25rem;
                            display: inline-block;
                            background-image: linear-gradient(
                            to right,
                            #25a9e0,
                            #187aba
                            ); //最后三行为文字渐变CSS
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                }
                .content{
                    .left {
                        display: flex;
                        justify-content: space-between;
                        .item {
                            font-size: .3rem;
                            .itemName {
                                padding: 0 0 .15rem .05rem;

                            }
                            .selected {
                                border-bottom: 2px solid #197dbe;
                                color: #197dbe;
                            }
                        }
                    }
                    .right {
                        margin-top: .15rem;
                        width: 100;
                        height: 3.5rem;
                        .bm-view {
                        height: 3.5rem;
                        }
                    }
                }
            }
        }
    }
  }
}
</style>